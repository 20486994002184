import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { RiArrowDownLine, RiArrowRightSLine } from "react-icons/ri"

import VideoCard from "./video-card"

const VideoMaker = ({ data }) => (
  <section className="home-posts">
    <h2>Últimos videos en <strong>Youtube</strong> <span class="icon -right"><RiArrowDownLine /></span></h2>
    <div className="grids col-1 sm-2 lg-3">
      {data}
    </div>
    <Link className="button"
      to="https://www.youtube.com/channel/UCuNmDUTbybMR0BrnC1pKyYA "
      target="_blank">
      Ver más<span class="icon -right">
        <RiArrowRightSLine /></span>
    </Link>
  </section>
)

export default function VideosListHome() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allYoutubeVideo(limit: 6){
            nodes{
              id
              title
              videoId
              publishedAt(formatString: "MMMM DD, YYYY")
              localThumbnail{
                  childImageSharp {
                    fluid(maxWidth: 480, maxHeight: 380, quality: 80, srcSetBreakpoints: [960, 1440]) {
                      originalImg
                    }
                  }
              }
            }
          }
        }`
      }

      render={data => {
        const posts = data.allYoutubeVideo.nodes
          .map(node =>
            <VideoCard key={node.id} data={node} />
          )
        return <VideoMaker data={posts} />
      }
      }
    />
  )
}