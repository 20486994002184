import React from "react"
import { Link } from "gatsby"

const VideoCard = ({ data }) => (
  <article className="post-card">
    {data.id && data.localThumbnail ?
      (
        <Link to={'https://www.youtube.com/watch?v=' + data.videoId} target="_blank">
          <img src={data.localThumbnail.childImageSharp.fluid.originalImg}
            alt={data.title}
          />
        </Link>
      ) : ""
    }
    <div class="post-content">
      <h2 className="title">{data.title}</h2>
      <p className="meta"><time>{data.publishedAt}</time></p>
    </div>
  </article>
)

export default VideoCard