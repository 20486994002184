import React from "react"
import defipulse from "../assets/img/defipulse-logo.jpg"
import aave from "../assets/img/aave.png"
import { RiArrowDownLine } from "react-icons/ri"


export default function Collaborators() {
  return (
    <section className="collaborators">
      <h2>Nuestros colaboradores <span class="icon -right"><RiArrowDownLine /></span></h2>
      <div className="grids col-1 sm-2 lg-3">
        <div><a href="https://defipulse.com/" target="_blank"> <img hew src={defipulse} alt="Logo" /></a></div>
        <div><a href="https://app.aave.com/?referral=65" target="_blank"> <img hew src={aave} alt="Aave" /></a></div>
      </div>
    </section>
  )
}